import React from 'react';
//import ReactGA from 'react-ga';
import styled from 'styled-components';
import { Route, Link, Switch, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { HomePage, WorkPage, ScriptPage, EventsPage/*, FunPackPage*/ } from 'pages';
import { ButtonA, ButtonLink } from 'components';
import SocialButtons from 'components/SocialButtons';
import ScrollToTop from 'components/ScrollToTop';
import logoSvg from "img/logo.svg";
import theme from "theme.js";
import { links } from "data/navigation";
import { events } from "data/events";

const HeaderWrapper = styled.header`
  background: ${props => props.theme.colours.primary};
`;
const Header = styled.div`
  color: ${props => props.theme.colours.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: ${props => (props.theme.maxWidth + 200) + 'px'};
  margin: 0 auto;
  height: 130px; 
`;
const StyledButton = styled(ButtonLink)`
  margin-right: 36px;
  display: none;
  @media (max-width: ${props => props.theme.maxWidth / 1.5}px) and (min-width: 401px) {
    display: block;
  }
  @media (max-width: 400px) {
    display: block;
    margin-right: 18px;
  }
`;
const StyledA = styled(ButtonA)`
  margin-right: 36px;
  display: none;
  @media (max-width: ${props => props.theme.maxWidth / 1.5}px) and (min-width: 401px) {
    display: block;
  }
  @media (max-width: 400px) {
    display: block;
    margin-right: 18px;
  }
`;
const Footer = styled.footer`
  background: ${props => props.theme.colours.dark};
  color: ${props => props.theme.colours.white};
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  @media (max-width: ${props => (props.theme.maxWidth / 1.5)}px) {
    height: 104px;
  }
`;
const TagLine = styled.h2`
  @media (max-width: ${props => props.theme.maxWidth}px) {
    display: none;
    visibility: hidden;
    flex: 0;
  }
  display: inline-block;
  flex: 1;
  font-size: 22px;
  font-family: 'Lato';
  margin: 0 0 0 42px;
  padding: 0;
  vertical-align: baseline;
  font-weight: 300;
  font-style: italic;
`;

const LinkList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 24px;
`
const Li = styled.li`
  padding: 0 15px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & + & {
    border-left: 1px solid white;
  }
`;
const StyledLink = styled(Link)`
    color: ${props => props.theme.colours.white};
    text-decoration: none;
    text-align: center;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  @media (min-width: ${props => (props.theme.maxWidth / 1.5) + 1}px) {
    display: none !important;
  }
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 12;
`;

const SocialButtonsContainer = styled.div`
  margin-right: 24px;
  @media (max-width: ${props => props.theme.maxWidth / 1.5}px) {
    display: none;
  }
`;

const Nav = styled.nav`
  @media (max-width: ${props => (props.theme.maxWidth / 1.5)}px) {
    display: none;
  }
`;

const Main = styled.main`
  flex: 1 0 auto;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colours.mediumPurple,
    borderTop: `3px solid ${theme.colours.secondary}`
  },
  tabsroot: {
    minWidth: '40px'
  },
  selected: {

  }
});

export default function App() {
  //  const trackingId = 'UA-174498756-1';
  let { pathname } = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(pathname);
  // ReactGA.initialize(trackingId);

  return (
    <>
      <ScrollToTop></ScrollToTop>
      <HeaderWrapper>
        <Header>
          <Link to="/"><img src={logoSvg} height="102" width="172" alt="Pumpkin Pantos Logo" /></Link>
          <TagLine>Top quality, hilarious and fun-packed pantomimes.</TagLine>
          <Nav>
            <LinkList>
              {links.map(({ label, title, path }) =>
                <Li><StyledLink key={label} to={path}>{title || label}</StyledLink></Li>
              )}
            </LinkList>
          </Nav>
          <SocialButtonsContainer>
            <SocialButtons></SocialButtons>
          </SocialButtonsContainer>
          {events.length > 0 && (pathname === '/events' ? <StyledA href="https://www.ticketsource.co.uk/PUMPKINPANTOS">Book Tickets</StyledA> : <StyledButton to="/events">Book Tickets</StyledButton>)}
        </Header>
      </HeaderWrapper>
      <Main>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/work" component={WorkPage} />
          <Route path="/scripts" component={ScriptPage} />
          <Route path="/events" component={EventsPage} />
          {/* <Route path="/funpack" component={FunPackPage} /> */}
        </Switch>
      </Main>
      <Footer>
        &copy; Pumpkin Pantos 2020-2024
      </Footer>
      <StyledBottomNavigation
        value={value}
        onChange={(_, newValue) => { console.log('newValue', newValue, pathname); setValue(newValue); }}
        showLabels={false}
        className={classes.root}
      >
        {links.map(({ label, icon, path, showLabel }) =>
          <BottomNavigationAction key={label} component={Link} classes={{ root: classes.tabsroot, selected: classes.selected }} label={label} icon={<Icon src={icon} />} to={path} showLabel={showLabel} />
        )}
      </StyledBottomNavigation>
    </>
  );
}